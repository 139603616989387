<template>
  <v-autocomplete
    :value="value"
    :items="items"
    :label="label"
    item-text="SEARCH_NAME"
    item-value="ID"
    :disabled="disabled"
    :required="required"
    :dense="dense"
    :rules="rules"
    :search-input.sync="search"
    return-object
    clearable
    color="teal"
    :hint="hint"
    persistent-hint
    @change="change($event)"
  ></v-autocomplete>
</template>

<script>
export default {
  name: 'AutocompleteClient',
  props: {
    value: {
      required: false,
    },
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    dense: {
      type: Boolean,
      required: false,
      default: false,
    },
    rules: {
      type: Array,
      required: false,
      default: () => [],
    },
    hint: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    search: null,
    items: [],
    timer: null,
  }),
  watch: {
    search(val) {
      const findItem = this.items.find((item) => item.SEARCH_NAME === val);
      if (findItem) return false;

      if (val && val.length >= 3) {
        if (this.timer) clearTimeout(this.timer);

        this.timer = setTimeout(() => {
          this.getClients(val);
        }, 500);
      } else {
        this.items = [];
      }

      return val;
    },
  },
  methods: {
    getClients(val) {
      const params = {
        order: {
          UF_NAME: 'ASC',
        },
        filter: {
          UF_ACTIVE: '1',
          0: {
            LOGIC: 'OR',
            0: {
              '%=UF_NAME': `%${val}%`,
            },
            1: {
              '%=UF_PHONE': `%${val}%`,
            },
          },
        },
        select: [
          'ID',
          'UF_NAME',
          'UF_DEPOSIT',
          'UF_PHONE',
          'UF_ADDRESS',
          'UF_STREET',
          'UF_HOUSE',
          'UF_FLOOR',
          'UF_FLAT',
        ],
        limit: 10,
        offset: 0,
      };

      this.$store.dispatch('getClients', { params }).then((response) => {
        this.items = response.data.data.result.map((item) => {
          const client = { ...item };
          const address = [
            client.UF_ADDRESS,
            client.UF_STREET,
            client.UF_HOUSE,
            client.UF_FLOOR,
            client.UF_FLAT,
          ].filter(Boolean);

          client.SEARCH_NAME = `${client.UF_NAME} (${client.UF_PHONE})`;
          client.ADDRESS = address.join(', ');

          return client;
        });
      });
    },
    getClient() {
      const params = {
        order: {
          UF_NAME: 'ASC',
        },
        filter: {
          ID: this.value,
        },
        select: [
          'ID',
          'UF_NAME',
          'UF_DEPOSIT',
          'UF_PHONE',
          'UF_ADDRESS',
          'UF_STREET',
          'UF_HOUSE',
          'UF_FLOOR',
          'UF_FLAT',
        ],
        limit: 1,
        offset: 0,
      };

      this.$store.dispatch('getClients', { params }).then((response) => {
        this.items = response.data.data.result.map((item) => {
          const client = { ...item };
          const address = [
            client.UF_ADDRESS,
            client.UF_STREET,
            client.UF_HOUSE,
            client.UF_FLOOR,
            client.UF_FLAT,
          ].filter(Boolean);

          client.SEARCH_NAME = `${client.UF_NAME} (${client.UF_PHONE})`;
          client.ADDRESS = address.join(', ');

          return client;
        });

        this.$emit('inputObject', this.items[0]);
      });
    },
    change(val) {
      const value = (val) ? val.ID : null;

      this.$emit('change', value);
      this.$emit('input', value);
      this.$emit('inputObject', val);
    },
  },
  created() {
    if (this.value) {
      this.getClient();
    }
  },
};
</script>
