<i18n>
{
  "ru": {
    "title": "Рассылки"
  },
  "he": {
    "title": "רשימת תפוצה"
  }
}
</i18n>

<template>
  <div>
    <h1 class="text-h4 mb-2">{{ $t('title') }}</h1>

    <v-row class="align-center mb-5">
      <template v-for="(item, index) in filter">
        <v-col
          cols="3"
          :key="`filter-item-${index}`"
        >
          <template v-if="item.type === 'datePicker'">
            <v-menu
              v-model="item.open"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="item.value"
                  :label="item.name"
                  prepend-icon="mdi-calendar"
                  readonly
                  clearable
                  color="teal"
                  v-bind="attrs"
                  v-on="on"
                  @input="changeFilter()"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="item.value"
                no-title
                scrollable
                color="teal"
                range
                @change="item.open = false; changeFilter()"
              >
              </v-date-picker>
            </v-menu>
          </template>

          <template v-else-if="item.type === 'client'">
            <AutocompleteClient
              v-model="item.value"
              :label="item.name"
              @input="changeFilter()"
            ></AutocompleteClient>
          </template>

          <template v-if="item.type === 'select'">
            <v-select
              v-model="item.value"
              :items="item.list"
              item-text="name"
              item-value="id"
              :label="item.name"
              color="teal"
              clearable
              @change="changeFilter()"
            ></v-select>
          </template>

          <template v-if="item.type === 'text'">
            <v-text-field
              v-model="item.value"
              :label="item.name"
              :rules="item.rules"
              color="teal"
              clearable
              @change="changeFilter()"
            ></v-text-field>
          </template>
        </v-col>
      </template>

      <v-col
        cols="auto ms-auto"
      >
        <v-btn
          color="teal"
          dark
          to="/send-messages/add"
          v-if="$store.getters.isAdmin"
        >{{ $t('btn.add') }}</v-btn>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="10"
      class="elevation-1"
      :footer-props="footer"
      :server-items-length="total"
      :page.sync="page"
      :loading="loading"
    >
      <template v-slot:item.UF_TYPE="{ item }">
        {{ (find = sendMessagesTypes.find((type) => type.id === item.UF_TYPE)) ? find.name : '-' }}
      </template>

      <template v-slot:item.UF_STATUS="{ item }">
        {{ (find = sendMessagesStatus.find((type) => type.id === item.UF_STATUS))
          ? find.name : '-' }}
      </template>

      <template v-slot:item.btn="{ item }">
        <v-menu
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              dark
              icon
              color="teal"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon dark>
                mdi-dots-vertical
              </v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              :to="`/send-messages/add?text=${item.UF_TEXT}`"
            >
              <v-list-item-title>
                {{ $t('btn.repeat') }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import AutocompleteClient from '@/components/AutocompleteClient.vue';

export default {
  name: 'SendMessagesList',
  components: {
    AutocompleteClient,
  },
  data: (vm) => ({
    items: [],
    filter: [
      {
        name: vm.$t('props.UF_DATE'),
        code: 'UF_DATE',
        value: null,
        type: 'datePicker',
        open: false,
      },
      {
        name: vm.$t('props.UF_CLIENTS'),
        code: 'UF_CLIENTS',
        value: null,
        type: 'client',
        list: [],
      },
      {
        name: vm.$t('props.UF_TYPE'),
        code: 'UF_TYPE',
        value: null,
        type: 'select',
        list: [
          {
            id: 12,
            name: vm.$t('sendMessagesTypes.notification'),
          },
          {
            id: 13,
            name: vm.$t('sendMessagesTypes.sending'),
          },
        ],
      },
      {
        name: vm.$t('props.UF_STATUS'),
        code: 'UF_STATUS',
        value: null,
        type: 'select',
        list: [
          {
            id: 14,
            name: vm.$t('sendMessagesStatus.inProgress'),
          },
          {
            id: 15,
            name: vm.$t('sendMessagesStatus.success'),
          },
          {
            id: 16,
            name: vm.$t('sendMessagesStatus.error'),
          },
        ],
      },
    ],
    headers: [
      {
        text: '№',
        value: 'ID',
        sortable: false,
      },
      {
        text: vm.$t('props.UF_DATE'),
        value: 'UF_DATE',
        sortable: false,
      },
      {
        text: vm.$t('props.UF_MESSAGE'),
        value: 'UF_TEXT',
        sortable: false,
      },
      {
        text: vm.$t('props.UF_TYPE'),
        value: 'UF_TYPE',
        sortable: false,
      },
      {
        text: vm.$t('props.UF_STATUS'),
        value: 'UF_STATUS',
        sortable: false,
      },
      {
        text: '',
        value: 'btn',
        align: 'right',
        sortable: false,
      },
    ],
    footer: {
      'disable-items-per-page': true,
      'items-per-page-options': [50, 100],
    },
    page: 1,
    limit: 50,
    total: 0,
    loading: false,
  }),
  watch: {
    page() {
      this.getSendMessages();
    },
  },
  computed: {
    sendMessagesTypes() {
      return this.$store.getters.getSendMessagesTypes;
    },
    sendMessagesStatus() {
      return this.$store.getters.getSendMessagesStatus;
    },
    paramsFilter() {
      const params = {};
      const filterProps = this.filter.filter((item) => item.value || item.value === false);

      filterProps.forEach((item) => {
        params[item.code] = item.value;
      });

      return params;
    },
  },
  methods: {
    changeFilter() {
      this.page = 1;
      this.getSendMessages();
    },
    getSendMessages() {
      this.loading = true;

      const params = {
        order: {
          ID: 'DESC',
        },
        filter: this.paramsFilter,
        select: [
          'ID',
          'UF_DATE',
          'UF_TEXT',
          'UF_TYPE',
          'UF_STATUS',
        ],
        limit: this.limit,
        offset: (this.page - 1) * this.limit,
      };

      this.$store.dispatch('getSendMessages', { params }).then((response) => {
        this.items = response.data.data.result;
        this.total = response.data.data.count;

        this.loading = false;
      });
    },
  },
  created() {
    this.getSendMessages();
  },
};
</script>
